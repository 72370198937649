/*
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-02-11 11:23:07
 */

import {createRouter,createWebHashHistory} from 'vue-router';
import { defineAsyncComponent } from 'vue';
// import Layout from '@/layout/Index.vue';
import Index from '@/views/Index.vue';
import Login from '@/views/login/Login.vue';
import ForgetPwd from '@/views/login/ForgetPwd.vue';
import BecomeDealer from '@/views/login/BecomeDealer.vue';
import OrderList from '@/views/order/OrderList.vue';

import ErrorPage from '@/views/login/404.vue';
import StopPage from '@/views/login/Stop.vue';
import RegisteredSuccessful from '@/views/login/RegisteredSuccessful.vue';


const routes = [
    // {
    //     path:'/',
    //     name:'Index',
    //     component: Index,
    //     redirect:'/OrderList',
    //     children:[
    //         {
    //             path:'/OrderList',
    //             name:'OrderList',
    //             component:OrderList,
    //             meta:{ 
    //                 title:"下单列表",
    //                 icon:"IconApps",
    //                 locale:"menu.orderList",
    //                 requiresAuth:true
    //             },
    //         },
            
    //     ],
    // },
    // {
    //     path:'/Login',
    //     name:'Login',
    //     component:Login,//() => import('@/views/Login.vue'),
    // },
    {
        path:'/BecomeDealer',
        name:'BecomeDealer',
        component: BecomeDealer,
    },
    // {
    //     path:'/ForgetPwd',
    //     name:'ForgetPwd',
    //     component: ForgetPwd, 
    // },
    {
        path:'/RegisteredSuccessful',
        name:'RegisteredSuccessful',
        component:RegisteredSuccessful,
    },
    {
        path:'/ErrorPage',
        name:'404',
        component:ErrorPage,
    },
    {
        path:'/StopPage',
        name:'Stop',
        component:StopPage,
    },
    {
        path: '/:catchAll(.*)',  // vue3 使用 /:catchAll(.*) 或 /:pathMatch(.*)
        redirect: '/StopPage',
    },
    
]

const router = createRouter({
    history:createWebHashHistory(),
    routes,
})


export default router
