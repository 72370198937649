<!--
 * @Author: caizexin caizexin@kjgb.net
 * @Date: 2023-02-11 14:28:41
-->
<template>
    <div class="errorPage">
        <!-- <img src="@/assets/image/404.png" alt=""> -->
        <div class="msg">
            <div class="sorry">{{ $t('base.registeredSuccessful') }}</div>
        </div>
    </div>
</template>

<script setup>
    import {onMounted, onUnmounted, reactive, ref} from 'vue';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n()
    
    const router = useRouter()
    const state = reactive({
        time:5,
        timeInter:null,
    })

    // function goHomePage(){
    //     router.replace('/Home')
    // }

    onMounted(()=>{
        // clearInterval()
        // state.timeInter = setInterval(()=>{
        //     if(state.time == 0){
        //         router.replace('/Home') 
        //     }
        //     state.time -= 1

        // },1000)
    })

    onUnmounted(()=>{
        // clearInterval(state.timeInter)
        // state.timeInter = null
    })
    

</script>

<style lang="less" scoped>
.errorPage{
    margin-top: 200px;
    text-align: center;

    img{
        display: inline-block;
        width: 500px;
    }

    .msg{
        display: inline-block;
        margin-top:100px;
        width: 400px;
        vertical-align: top;
        text-align: left;
        .sorry{
            width: 700px;
            font-size: 40px;
            font-weight: bold;
            color: #1890ff;
        }
        .noPage{
            margin: 10px 0 5px;
            font-size: 20px;
            font-weight: bold;
        }
        .tip{
            color: #999;
            margin-bottom: 30px;
        }
    }
    
}
</style>